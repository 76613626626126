import React, { Component, Fragment } from 'react'

import PageTitle from '../../Layout/AppMain/PageTitle'

import { Container, Row, Card, CardBody, Col, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Api from '../../utils/api'

export class DeploymentsNew extends Component {
  state = { name: '' }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    this.setState(newState)
  }

  handleCreateDeployment() {
    Api.post('/configurations', this.state)
      .then(responseJSON => {
        this.props.history.push(`/configurations/${responseJSON.id}`)
      })
  }

  render() {
    return (

      <Fragment>
        <PageTitle
          heading="New Configuration"
          subheading=""
          icon="pe-7s-graph icon-gradient bg-ripe-malin"
        />
        <Container fluid>
          <Row>
            <Col md="6">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Configuration Settings</CardTitle>
                  <Form onSubmit={(e) => { e.preventDefault(); this.handleCreateDeployment() }}>
                    <FormGroup>
                      <Label for="exampleEmail">Name</Label>
                      <Input autoFocus value={this.state.name} onChange={e => this.handleChange(e, 'name')} type="text" name="name"
                      />
                    </FormGroup>

                    <Button color="primary" className="mt-1">Create</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </Fragment>

    )
  }
}
