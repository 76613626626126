import React, { Component } from 'react'
import cx from 'classnames'

class PageTitle extends Component {
  render() {
    let {
      image,
      heading,
      icon,
      subheading,
    } = this.props

    return (

      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">

            {image != undefined && (
              <div
                className={cx('page-title-image')}>
                <img src={image} width="40px" />
              </div>
            )}
            {image == undefined && (
              <div
                className={cx('page-title-icon')}>
                <i className={icon} />
              </div>
            )}
            <div>
              {heading}
              <div
                className={cx('page-title-subheading')}>
                {subheading}
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default PageTitle
