import { Route } from 'react-router-dom'
import React, { Component } from 'react'
import { withRouter, Switch } from 'react-router'
import { connect } from 'react-redux'

import { updateCurrentUser, updateCurrentAccount } from '../../actions'

import { DeploymentsPage } from '../../Pages/Deployments'

import Login from '../../Pages/UserPages/Login'
import { SettingsPage } from '../../Pages/Settings'
import BillingPage from '../../Pages/Billing'
import AppHeader from '../AppHeader'
import AppSidebar from '../AppSidebar'
import AppFooter from '../AppFooter'
import { UsersPage } from '../../Pages/Users'
import { DevicesPage } from '../../Pages/Devices'
import Api from '../../utils/api'
import { clearSessionData, hasSessionData } from '../../utils/session'

class GoogleAnalytics extends React.Component {
  componentDidUpdate(prevProps) {
    const gtag = window.gtag

    if (prevProps.location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return
    }

    if (this.props.history.action === 'PUSH' &&
      typeof (gtag) === 'function') {
      gtag('config', 'UA-847542-4', {
        'page_title': document.title,
        'page_location': this.props.location.href,
        'page_path': this.props.location.pathname + this.props.location.search,
      })
    }
  }

  render() {
    return null
  }
}

class AppMain extends Component {
  componentDidMount() {
    var self = this

    Api.get('/users/me')
      .then(responseJSON => {
        this.props.updateCurrentUser(responseJSON)
      })
      .catch(() => {
        if (self.props.location.pathname != '/users/sign_in' && self.props.location.pathname != '/users/register') {
          self.props.history.push('/users/sign_in')
        }
      })

    Api.get('/account')
      .then(responseJSON => {
        this.props.updateCurrentAccount(responseJSON)
      })
      .catch(() => {
        if (self.props.location.pathname != '/users/sign_in' && self.props.location.pathname != '/users/register') {
          self.props.history.push('/users/sign_in')
        }
      })
  }

  render() {
    if (this.props.location.pathname === '/users/sign_in' || this.props.location.pathname === '/users/register') {
      if (hasSessionData()) {
        clearSessionData()
      }
    }

    if (this.props.location.pathname === '/users/sign_in') {
      return <Route path='/users/sign_in' component={Login} />
    }

    if (this.props.location.pathname === '/users/register') {
      return <Route path='/users/register' component={Login} />
    }

    if (!this.props.currentUser?.id || !this.props.currentAccount?.id) {
      return null
    }

    return (
      <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar ">
        <AppHeader />
        <div className="app-main app-theme-white ">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Switch>
                <Route path='/configurations' component={DeploymentsPage} />
                <Route path='/devices' component={DevicesPage} />
                <Route path='/billing' component={BillingPage} />
                <Route path='/users' component={UsersPage} />
                {/* <Route path='/groups' component={GroupsPage} /> */}
                <Route path='/settings' component={SettingsPage} />
              </Switch>
              <GoogleAnalytics location={this.props.location} history={this.props.history} />

            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentAccount: state['currentAccount'],
  currentUser: state['currentUser'],
})

const mapDispatchToProps = dispatch => ({
  updateCurrentAccount: account => dispatch(updateCurrentAccount(account)),
  updateCurrentUser: user => dispatch(updateCurrentUser(user)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppMain),
)
