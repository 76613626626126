import React, { Fragment } from 'react'

import AppMobileMenu from '../AppMobileMenu'

import logo from '../../assets/utils/images/logo-inverse.png'

import { Link } from 'react-router-dom'

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    }
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props
    setEnableClosedSidebar(!enableClosedSidebar)
  }

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  }

  render() {
    return (
      <Fragment>
        <div className="app-header__logo">

          <Link to={'/configurations'}>
            <img src={logo} width={170} alt={''} />
          </Link>
        </div>
        <AppMobileMenu />
      </Fragment>
    )
  }
}

export default HeaderLogo