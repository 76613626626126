import React, { Component, Fragment } from 'react'

import PageTitle from '../../Layout/AppMain/PageTitle'
import Select from 'react-select'

import { Container, Row, Card, CardBody, Col, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Api from '../../utils/api'

export class UsersEdit extends Component {
  state = { configurationId: 0, email: '', configurations: [] }

  async componentDidMount() {
    await this.loadConfigurations()

    this.loadConfig()
  }

  async loadConfigurations() {
    var self = this
    return Api.get('/configurations')
      .then(responseJSON => {
        self.setState({ configurations: responseJSON })
      })
      .catch(() => {
        self.props.history.push('/users/sign_in')
      })
  }
  loadConfig() {
    var self = this
    if (this.props.match.params.id != 'new') {
      Api.get(`/users/${this.props.match.params.id}`)
        .then(responseJSON => {
          self.setState(responseJSON)
        })
        .catch(() => {
          self.props.history.push('/users/sign_in')
        })
    }
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    this.setState(newState)
  }

  handleSaveUser() {
    var method = 'post'
    var url = '/users'
    if (this.props.match.params.id != 'new') {
      method = 'patch'
      url += '/' + this.props.match.params.id
    }

    Api[method](url, this.state)
      .then(() => {
        this.props.history.push('/users')
      })
  }

  handleDeleteUser() {
    var url = '/api/v1/users/' + this.props.match.params.id

    Api.delete(url)
      .then(() => {
        this.props.history.push('/users')
      })
  }

  render() {
    var defaultOption = null
    var selectNodes = Object.keys(this.state.configurations).map(key => {
      var o = ({ value: this.state.configurations[key].id, label: (<div>{this.state.configurations[key].name}</div>) })
      if (this.state.configurations[key].id == this.state['configurationId'] || (this.state['configuration'] && this.state.configurations[key].id == this.state['configuration'].id)) {
        defaultOption = o
      }
      return o
    })

    return (

      <Fragment>
        <PageTitle
          heading={`${this.props.match.params['id'] == 'new' ? 'New' : 'Edit'} User`}
          subheading=" "
          icon="pe-7s-graph icon-gradient bg-ripe-malin"
        />
        <Container fluid>
          <Row>
            <Col md="6">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>User Settings</CardTitle>
                  <Form onSubmit={(e) => { e.preventDefault(); this.handleSaveUser() }}>
                    <FormGroup>
                      <Label for="exampleSelect">Drive Configuration</Label>
                      <Select
                        onChange={(e) => {
                          this.setState({ configurationId: e.value })
                        }}
                        value={defaultOption}
                        type="select" name="type"
                        options={selectNodes}

                      />

                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">Email</Label>
                      <Input autoFocus value={this.state.email} onChange={e => this.handleChange(e, 'email')} type="email" name="name"
                      />
                    </FormGroup>

                    <Button color="primary" className="mt-1">Save User</Button>
                    {this.props.match.params.id != 'new' && (
                      <Button color="danger" className="mt-1" style={{ float: 'right' }} onClick={(e) => { e.preventDefault(); this.handleDeleteUser() }}>Delete User</Button>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>

        </Container>
      </Fragment>

    )
  }
}
