import React, { Component, Fragment } from 'react'
import { TransitionGroup } from 'react-transition-group'

import { connect } from 'react-redux'
import { CardFooter, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Tabs, { TabPane } from 'rc-tabs'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import TabContent from 'rc-tabs/lib/SwipeableTabContent'
import { Prompt } from 'react-router-dom'

import { withRouter } from 'react-router-dom'
import { updateCurrentUser } from '../../actions'
import Api from '../../utils/api'

class SettingsPageComponent extends Component {
  state = {
    accountName: '',
    settings: {},
    settingsDirty: false,
  }

  componentDidMount() {
    this.loadSettings()
  }

  handleChangeAccountName = e => {
    var newName = e.target.value
    this.setState({ 'accountName': newName })
    Api.patch('/account', { accountName: newName })
      .then(responseJSON => {
        this.props.updateCurrentUser(responseJSON)
      })
  }

  handleChangeSettings = (e, field) => {
    var newSettings = this.state.settings
    newSettings[field] = e.target.checked
    this.setState({ 'settings': newSettings })
    Api.patch('/account', { settings: newSettings })
      .then(() => {
        this.setState({ dirty: true })
      })
  }

  loadSettings() {
    var self = this
    Api.get('/account')
      .then(responseJSON => {
        self.setState({ settings: JSON.parse(responseJSON['defaultSettings']), accountName: responseJSON['name'] })
      })
  }

  render() {
    return (
      <Fragment>
        <Prompt
          when={this.state.settingsDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>

          <Tabs
            defaultActiveKey="1"
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent />}
          >
            <TabPane tab='Account' key="1">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Account Settings</CardTitle>
                  <Form>
                    <FormGroup>
                      <Label for="teamName">Team Name</Label>
                      <Input value={this.state.accountName} onChange={(e) => this.handleChangeAccountName(e)} type="text" name="teamName" id="teamName"
                        placeholder="" />
                    </FormGroup>
                    <FormGroup style={{ border: '1px solid rgb(206, 206, 206)', padding: '20px', borderRadius: '10px', textAlign: 'center', marginTop: '2rem' }}>
                      <div style={{ backgroundColor: 'white', textAlign: 'center', margin: '0 auto', marginBottom: '20px', marginTop: '-33px', width: '100px' }}>Danger Zone</div>
                      <Button className="btn-pill btn-shadow btn-wide fsize-1" size="lg" color="danger" onClick={(e) => { e.preventDefault(); this.handleDeleteAccount() }}>
                        Permanently Delete Account
                      </Button>
                    </FormGroup>
                  </Form>

                </CardBody>
                {this.state.dirty && (
                  <CardFooter className="text-center d-block p-3">

                    <Button onClick={() => { this.publishDeployment() }} color="alternate" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                      <span className="mr-2 opacity-7">

                      </span>
                      <span className="mr-1">
                        Publish Deployment
                      </span>
                    </Button>

                  </CardFooter>)}
              </Card>
            </TabPane>
            <TabPane tab='Defaults' key="2">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Default Drive Settings</CardTitle>
                  <Form>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'showInDock')} checked={this.state.settings['showInDock']} />{' '}
                        Show in Dock
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'startAtLogin')} checked={this.state.settings['startAtLogin']} />{' '}
                        Launch at Login
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'revealAfterConnect')} checked={this.state.settings['revealAfterConnect']} />{' '}
                        Reveal drives after connecting
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'showNotifications')} checked={this.state.settings['showNotifications']} />{' '}
                        Show Desktop notifications
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'checkForUpdates')} checked={this.state.settings['checkForUpdates']} /> {' '}
                        Automatically check for updates
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'disableOfflineSync')} checked={this.state.settings['disableOfflineSync']} /> {' '}
                        Disable Offline Sync
                      </Label>
                    </FormGroup>

                  </Form>

                </CardBody>
                {this.state.dirty && (
                  <CardFooter className="text-center d-block p-3">

                    <Button onClick={() => { this.publishDeployment() }} color="alternate" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                      <span className="mr-2 opacity-7">

                      </span>
                      <span className="mr-1">
                        Publish Deployment
                      </span>
                    </Button>

                  </CardFooter>)}
              </Card>
            </TabPane>
          </Tabs>

        </TransitionGroup>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentUser: user => {
      dispatch(updateCurrentUser(user))
    },
  }
}

export var SettingsPage = withRouter(
  connect(
    state => {
      return { currentUser: state['currentUser'] }
    },
    mapDispatchToProps,
  )(SettingsPageComponent),
)
