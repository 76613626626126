import React, { Component, Fragment } from 'react'

import { Modal, ModalHeader, ModalFooter, ModalBody, CardFooter, Container, Row, Card, Col, Form, FormGroup, Table, Input, Button, CardHeader } from 'reactstrap'
import { Link, Switch, Route } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'

import { UsersEdit } from './edit'

import moment from 'moment'
import Api from '../../utils/api'

class DeleteConfirmModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      name: '',
      id: '',
      deleteDisabled: true,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    if (field == 'name') {
      if (e.target.value == this.props.name) {
        this.setState({ deleteDisabled: false })
      } else {
        this.setState({ deleteDisabled: true })
      }
    }
    this.setState(newState)
  }

  handleRemoveUser() {
    var self = this

    Api.patch(`/users/${this.props.id}`, { status: 'remove' })
      .then(() => {
        self.props.loadUsers()
      })
  }

  render() {
    var self = this

    return (
      <span className="d-inline-block mb-2 mr-2">
               
        <a href="#" onClick={this.toggle}><FontAwesomeIcon className="ml-2 opacity-5" icon={faTrash}/></a>
        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>Remove User</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                                If you remove this user they will become unlicensed immediately.
                <br /><br />
                                To remove this user, type &quot;<span style={{ color: 'red' }}>{self.props.name}</span>&quot;, then click the remove button.<br />

                <Input autoFocus value={this.state.name} onChange={(e) => self.handleChange(e, 'name')} type="text"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>Cancel</Button>
            <Button disabled={this.state.deleteDisabled} color="danger" className="btn-pill btn-widee fsize-1" size="md" onClick={this.handleRemoveUser.bind(this)}>Remove</Button>{' '}
          </ModalFooter>
        </Modal>
      </span>
    )
  }
}

export class UsersIndex extends Component {
  state = {
    users: [],
    devices: [],
  }

  componentDidMount() {
    this.loadUsers()
  }

  loadUsers() {
    var self = this

    Api.get('/users')
      .then(responseJSON => {
        self.setState({ users: responseJSON })
      })
      .catch(() => {
        self.props.history.push('/users/sign_in')
      })

    Api.get('/devices')
      .then(responseJSON => {
        var d = responseJSON.filter((dd) => { return dd.user == null })

        self.setState({ devices: d })
      })
  }

  render() {
    return (
      <Fragment>
        <Container fluid>

          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader>
                                    Active Users

                </CardHeader>
                <Table responsive hover striped borderless className="align-middle mb-0">
                  <thead>
                    <tr style={{ height: '1.5rem' }}>
                      <th style={{ width: '30px' }}></th>
                      <th>Email</th>
                      <th>Configuration</th>
                      <th>Devices</th>
                      <th>Last Seen</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map(user => {
                      var sortedList = user.devices.sort((a, b) => {
                        return (new Date(b.lastSeen).valueOf() - new Date(a.lastSeen).valueOf())
                      })

                      return (<tr key={user.id} style={{ height: '3rem', fontSize: '1rem' }} >
                        <td><FontAwesomeIcon className="ml-2 opacity-5" icon={faUser} /></td>
                        <td><Link to={`${this.props.match.url}/${user.id}`}>{user.email}</Link></td>

                        <td>
                          {user.configuration && (
                            <Link to={`/configurations/${user.configuration.id}`}>{user.configuration.name}</Link>
                          )}
                        </td>
                        <td>
                          {user.devices.length}
                        </td>
                        <td>
                          {sortedList.length > 1 && (moment(user.devices[0].lastSeen).fromNow())}
                        </td>
                        <td><DeleteConfirmModal name={user.email} id={user.id} loadUsers={this.loadUsers.bind(this)}/></td>

                      </tr>)
                    })}

                    {this.state.devices.map(device => {
                      return (<tr key={'d' + device.id} style={{ height: '3rem', fontSize: '1rem' }} >
                        <td><FontAwesomeIcon className="ml-2 opacity-5" icon={faLaptop} /></td>
                        <td>unnamed - {device.name}</td>

                        <td>
                          {device.configuration && (
                            <Link to={`/configurations/${device.configuration.id}`}>{device.configuration.name}</Link>
                          )}
                        </td>
                        <td>
                                                   1
                        </td>
                        <td>
                          {moment(device.lastSeen).fromNow()}
                                                   
                        </td>

                      </tr>)
                    })}

                  </tbody>
                </Table>

                <CardFooter className="text-center d-block p-3">
                                    Unnamed Users: {this.state.devices.length}<br/>
                                    Named Users: {this.state.users.length}<br/>
                                    Total Users: {this.state.users.length + this.state.devices.length}<br/>
                  <br/>
                  <Link to={`${this.props.match.url}/new`}>
                    <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                      <span className="mr-2 opacity-7">

                      </span>
                      <span className="mr-1">
                                                New User
                      </span>
                    </Button>
                  </Link>

                </CardFooter>

              </Card>
              <a href="https://docs.expandrive.com/teams/users">Help - Users</a>
            </Col>
          </Row>

        </Container>
      </Fragment>
    )
  }
}

export class UsersPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route path={`${this.props.match.url}/:id`} component={UsersEdit} />
          <Route path={`${this.props.match.url}`} component={UsersIndex} />
        </Switch>
      </Fragment>

    )
  }
}

