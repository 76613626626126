
export const ConnectionTypes = {
  strongspace: {
    name: 'Strongspace',
    server: 'Strongspace.com',
    defaultNickname: 'Strongspace',
    brandingIcon: '/app/assets/images/branding/strongspace/strongspace80.png',
    branding: '/app/assets/images/branding/strongspace/strongspace400.png',
    protocol: 'strongspace',
    oauth: true,
  },
  sftp: {
    name: 'SSH (SFTP)',
    portPlaceholder: '22',
    brandingIcon: '/app/assets/images/branding/sftp/sftp80.png',
    branding: '/app/assets/images/branding/sftp/sftp120.png',
    protocol: 'sftp',
  },
  ftp: {
    name: 'FTP',
    portPlaceholder: '21',
    brandingIcon: '/app/assets/images/branding/ftp/ftp80.png',
    branding: '/app/assets/images/branding/ftp/ftp120.png',
  },
  s3: {
    name: 'Amazon S3',
    defaultServer: 's3.amazonaws.com',
    defaultNickname: 'Amazon S3',
    brandingIcon: '/app/assets/images/branding/s3/s380.png',
    branding: '/app/assets/images/branding/s3/s3120.png',
    sharedLinks: true,
    protocol: 's3',
    versions: true,
    defaultStsEndpoint: 'sts.amazonaws.com',
  },
  s3iam: {
    name: 'Amazon S3 with IAM Role',
    defaultServer: 's3.amazonaws.com',
    defaultNickname: 'Amazon S3',
    brandingIcon: '/app/assets/images/branding/s3/s380.png',
    branding: '/app/assets/images/branding/s3/s3120.png',
    sharedLinks: true,
    protocol: 's3',
    versions: true,
    defaultStsEndpoint: 'sts.amazonaws.com',

  },
  webdav: {
    name: 'WebDAV',
    serverPlaceholder: 'https://yourserver.com/dav',
    brandingIcon: '/app/assets/images/branding/webdav/webdav80.png',
    branding: '/app/assets/images/branding/webdav/webdav120.png',
  },
  smb: {
    name: 'SMB/CIFS',
    brandingIcon: '/app/assets/images/branding/smb/smb80.png',
    branding: '/app/assets/images/branding/smb/smb120.png',
    defaultNickname: 'File Share',
  },
  owncloud: {
    name: 'ownCloud',
    defaultNickname: 'ownCloud',
    brandingIcon: '/app/assets/images/branding/owncloud/owncloud80.png',
    branding: '/app/assets/images/branding/owncloud/owncloud400.png',
    protocol: 'webdav',
    defaultRemotePath: '/remote.php/webdav',
  },
  nextcloud: {
    name: 'Nextcloud',
    defaultNickname: 'Nextcloud',
    brandingIcon: '/app/assets/images/branding/nextcloud/icon80.png',
    branding: '/app/assets/images/branding/nextcloud/header400.png',
    protocol: 'webdav',
    defaultRemotePath: '/remote.php/webdav',
  },
  cloudfiles: {
    name: 'Rackspace Cloud Files',
    defaultNickname: 'Cloud Files',
    branding: '/app/assets/images/branding/cloudfiles/cloudfiles400.png',
    brandingIcon: '/app/assets/images/branding/cloudfiles/cloudfiles80.png',
    protocol: 'swift',
    defaultServer: 'https://identity.api.rackspacecloud.com/v2.0',
  },
  swift: {
    name: 'OpenStack Swift',
    defaultNickname: 'OpenStack Swift',
    serverOptionsLabel: 'Authentication Server',
    branding: '/app/assets/images/branding/openstack/openstack400.png',
    brandingIcon: '/app/assets/images/branding/openstack/openstack80.png',
  },
  dreamobjects: {
    name: 'DreamObjects',
    defaultNickname: 'DreamObjects',
    server: 'objects.dreamhost.com',
    brandingIcon: '/app/assets/images/branding/dreamobjects/dreamobjects80.png',
    branding: '/app/assets/images/branding/dreamobjects/dreamobjects400.png',
    protocol: 's3',
  },
  wasabi: {
    name: 'Wasabi',
    defaultNickname: 'Wasabi',
    server: 's3.wasabisys.com',
    hideServer: true,
    brandingIcon: '/app/assets/images/branding/wasabi/icon80.png',
    branding: '/app/assets/images/branding/wasabi/header400.png',
    protocol: 's3',
  },
  box: {
    name: 'Box.com',
    server: 'Box.com',
    defaultNickname: 'Box',
    brandingIcon: '/app/assets/images/branding/box/box80.png',
    branding: '/app/assets/images/branding/box/box300.png',
    protocol: 'box',
    oauth: true,
    sharedLinks: true,
    search: true,
  },
  dropbox: {
    name: 'Dropbox',
    server: 'dropbox.com',
    defaultNickname: 'Dropbox',
    brandingIcon: '/app/assets/images/branding/dropbox/dropbox80.png',
    branding: '/app/assets/images/branding/dropbox/dropbox400.png',
    protocol: 'dropbox',
    oauth: true,
    search: true,
    sharedLinks: true,
    versions: true,
  },
  gdrive: {
    name: 'Google Drive',
    server: 'googledrive.com',
    defaultNickname: 'Google Drive',
    brandingIcon: '/app/assets/images/branding/gdrive/gdrive80.png',
    branding: '/app/assets/images/branding/gdrive/gdrive.png',
    protocol: 'gdrive',
    oauth: true,
    sharedLinks: true,
    search: true,
    versions: true,
  },
  clouddrive: {
    name: 'Amazon Drive',
    server: 'amazon.com',
    defaultNickname: 'Amazon Drive',
    brandingIcon: '/app/assets/images/branding/clouddrive/clouddrive80.png',
    branding: '/app/assets/images/branding/clouddrive/clouddrive400.png',
    protocol: 'clouddrive',
    oauth: true,
  },
  gcloud: {
    name: 'Google Cloud Storage',
    defaultNickname: 'Google Storage',
    brandingIcon: '/app/assets/images/branding/gcloud/icon80.png',
    branding: '/app/assets/images/branding/gcloud/header400.png',
    serverOptionsLabel: 'Project Number',
    protocol: 'gcloud',
    oauth: true,
  },
  azure: {
    name: 'Azure Storage',
    server: 'storage.azure.com',
    defaultNickname: 'Azure Storage',
    brandingIcon: '/app/assets/images/branding/azure/icon80.png',
    branding: '/app/assets/images/branding/azure/header400.png',
    protocol: 'azure',
    oauth: true,
    sharedLinks: false,
    search: false,
    versions: false,
  },
  onedrive: {
    name: 'OneDrive',
    server: 'onedrive.live.com',
    defaultNickname: 'OneDrive',
    brandingIcon: '/app/assets/images/branding/onedrive/onedrive80.png',
    branding: '/app/assets/images/branding/onedrive/onedrive400.png',
    protocol: 'onedrive',
    oauth: true,
    sharedLinks: true,
    search: true,
    versions: true,
  },
  onedrivebusiness: {
    name: 'OneDrive for Business',
    defaultNickname: 'OneDrive Business',
    server: 'ondrive.live.com',
    brandingIcon: '/app/assets/images/branding/onedrive/onedrive80.png',
    branding: '/app/assets/images/branding/onedrive/onedrive400.png',
    protocol: 'onedrivebusiness',
    oauth: true,
    sharedLinks: true,
    search: true,
    versions: true,
  },
  sharepoint: {
    name: 'Sharepoint Online',
    defaultNickname: 'Sharepoint',
    server: 'sharepoint.com',
    brandingIcon: '/app/assets/images/branding/sharepoint/sharepoint80.png',
    branding: '/app/assets/images/branding/sharepoint/sharepoint400.png',
    protocol: 'sharepoint',
    oauth: true,
    sharedLinks: true,
    search: true,
    versions: true,

  },
  b2: {
    name: 'Backblaze B2',
    server: 'backblaze.com',
    defaultNickname: 'B2',
    brandingIcon: '/app/assets/images/branding/b2/b2-160.png',
    branding: '/app/assets/images/branding/b2/b2-800.png',
    protocol: 'b2',
    oauth: false,
    rename: false,
  },
  hubic: {
    name: 'hubiC',
    defaultNickname: 'hubiC',
    server: 'hubic.com',
    branding: '/app/assets/images/branding/hubic/hubic300.png',
    brandingIcon: '/app/assets/images/branding/hubic/hubic80.png',
    protocol: 'swift',
    oauth: true,
  },
}
