import React, { Component, Fragment } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import PageTitle from '../../Layout/AppMain/PageTitle'

import { ConnectionTypes } from '../../config/constants'

// Examples

import { CardFooter, Container, Row, Card, CardBody, Col, CardTitle, Form, FormGroup, Label, Table, Input, Button } from 'reactstrap'
import Tabs, { TabPane } from 'rc-tabs'
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar'
import TabContent from 'rc-tabs/lib/SwipeableTabContent'
import { Link, Prompt } from 'react-router-dom'

import * as CopyToClipboard from 'react-copy-to-clipboard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHdd } from '@fortawesome/free-solid-svg-icons'
import Api from '../../utils/api'

export class DeploymentsEdit extends Component {
  state = {
    favorites: [],
    settings: {},
    licenseSettings: {},
    name: '',
    settingsDirty: false,
    dirty: false,
    key: '',
    copied: '',

  }

  componentDidMount() {
    this.loadDeployment(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id == this.props.match.params.id) {
      return
    }

    this.loadDeployment(this.props.match.params.id)
  }

  handleChangeSettings = (e, field) => {
    var newSettings = this.state.settings
    if (e.target.type == 'checkbox')
      newSettings[field] = e.target.checked
    else
      newSettings[field] = e.target.value

    this.setState({ 'settings': newSettings, 'settingsDirty': true })
    Api.patch(`/configurations/${this.props.match.params.id}`, { settings: newSettings })
      .then(() => {
        this.setState({ dirty: true })
      })
  }

  handleChangeLicenseSettings = (e, field) => {
    var newSettings = this.state.licenseSettings
    newSettings[field] = e.target.checked
    this.setState({ 'licenseSettings': newSettings })
    Api.patch(`/configurations/${this.props.match.params.id}`, { licenseSettings: newSettings })
      .then(() => {
        this.setState({ dirty: true })
      })
  }

  loadDeployment(deploymentId) {
    var self = this
    Api.get(`/configurations/${deploymentId}`)
      .then(responseJSON => {
        self.setState({ key: responseJSON['key'], dirty: responseJSON['dirty'], name: responseJSON['name'], settings: JSON.parse(responseJSON['settings']), licenseSettings: JSON.parse(responseJSON['licenseSettings']), favorites: JSON.parse(responseJSON['favorites']) })
      })
  }

  publishDeployment() {
    var self = this

    Api.post(`/configurations/${this.props.match.params.id}/publish`)
      .then(() => {
        self.loadDeployment(this.props.match.params.id)
        // alert(JSON.stringify(responseJSON))
        // self.setState({ name: responseJSON["name"], settings: JSON.parse(responseJSON["settings"]), favorites: JSON.parse(responseJSON["favorites"]) })
      })
  }

  licenseKeyWithDashes(width = 5) {
    var k = ''
    for (var i = 0; i < this.state.key.length; i += width) {
      var slice = this.state.key.slice(i, i + width)
      if (i + width < this.state.key.length) {
        slice += '-'
      }
      k += slice
    }
    return k
  }

  render() {
    var self = this
    return (
      <Fragment>
        <Prompt
          when={this.state.settingsDirty}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <PageTitle
          heading={`${this.state.name}`}
          subheading={<Fragment>License Key: <CopyToClipboard text={this.licenseKeyWithDashes()}
            onMouseOver={() => {
              if (this.state.copied == '')
                self.setState({ copied: 'Copy' })
            }}
            onMouseLeave={() => {
              if (this.state.copied == 'Copy')
                self.setState({ copied: '' })
            }}
            onCopy={() => {
              self.setState({ copied: 'Copied!' })
              setTimeout(() => {
                self.setState({ copied: '' })
              }, 1000)
            }}>
            <span style={{ cursor: 'pointer' }}>{this.licenseKeyWithDashes()} <span className={'copy-text' + (this.state.copied != '' ? ' active' : ' inactive')}>{this.state.copied}</span></span>
          </CopyToClipboard></Fragment>}
          icon="pe-7s-edit icon-gradient bg-ripe-malin"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>

          <Tabs
            defaultActiveKey="1"
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent />}
          >
            <TabPane tab='Drives' key="1">
              <Fragment>
                <Container fluid>

                  <Row>
                    <Col md="12">
                      <Card className="main-card mb-3">

                        <Table responsive hover striped borderless className="align-middle mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: '30px' }}><FontAwesomeIcon className="ml-2 opacity-5" icon={faHdd} /></th>
                              <th>Nickname</th>
                              <th>Drive Type</th>
                              <th>Windows Drive Letter</th>

                              <th>Remote Path</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.favorites.map(entry => {
                              return (<tr key={`${entry.id}`}>
                                <td><Link to={`${this.props.match.url}/drives/${entry.id}`}><img style={{ paddingLeft: '5px' }} width="30px" src={ConnectionTypes[entry.type] && ConnectionTypes[entry.type].brandingIcon} /></Link></td>
                                <td className="text-left text-muted"><Link to={`${this.props.match.url}/drives/${entry.id}`}>{entry.name || '(unnamed)'}</Link></td>
                                <td>{ConnectionTypes[entry.type]?.name}</td>
                                <td>{entry.mountpoint}</td>

                                <td colSpan="3">{entry.remotePath}</td>
                              </tr>)
                            })}

                          </tbody>
                        </Table>
                        <CardFooter className="text-center d-block p-3">
                          <Link to={`${this.props.match.url}/drives/new`}>
                            <Button color="success" className="btn-pill btn-shadow btn-wide fsize-1" style={{ marginTop: '0.5rem' }} size="lg">
                              <span className="mr-2 opacity-7">

                              </span>
                              <span className="mr-1">
                                New Drive
                              </span>
                            </Button>
                          </Link>{' '}
                          {this.state.dirty && (
                            <Button onClick={() => { this.publishDeployment() }} style={{ marginTop: '0.5rem' }} color="alternate" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">

                              <div className="mr-1">
                                Publish Configuration
                              </div>
                            </Button>
                          )}

                        </CardFooter>

                      </Card>
                      <a href="https://docs.expandrive.com/teams/configurations#drive-settings">Help - Drive Settings</a>
                    </Col>
                  </Row>

                </Container>
              </Fragment>
            </TabPane>
            <TabPane tab='App Settings' key="2">
              <Row>

                <Col md='6'>
                  <Card className="main-card mb-6">
                    <CardBody>
                      <CardTitle>App</CardTitle>
                      <Form>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'showInDock')} checked={this.state.settings['showInDock']} />{' '}
                            Show in Dock
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'startAtLogin')} checked={this.state.settings['startAtLogin']} />{' '}
                            Launch at Login
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'revealAfterConnect')} checked={this.state.settings['revealAfterConnect']} />{' '}
                            Reveal drives after connecting
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'showNotifications')} checked={this.state.settings['showNotifications']} />{' '}
                            Show Desktop notifications
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'checkForUpdates')} checked={this.state.settings['checkForUpdates']} /> {' '}
                            Automatically check for updates
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeSettings(e, 'disableOfflineSync')} checked={this.state.settings['disableOfflineSync']} /> {' '}
                            Disable Offline Sync
                          </Label>
                        </FormGroup>
                        <br />
                        <CardTitle>SSO Configuration</CardTitle>
                        <FormGroup>
                          <Label >Domain Hint</Label>
                          <Input type="text" name="domain_hint"
                            onChange={(e) => { this.handleChangeSettings(e, 'domain_hint') }}
                            value={this.state.settings['domain_hint']} />
                        </FormGroup>
                        <br />
                        <CardTitle>Linux Mount Location</CardTitle>
                        <FormGroup>
                          <Label >Root Mount Path</Label>
                          <Input type="text" name="mountLocation"
                            onChange={(e) => { this.handleChangeSettings(e, 'mountLocation') }}
                            value={this.state.settings['mountLocation']} />
                        </FormGroup>

                      </Form>

                    </CardBody>
                    {this.state.dirty && (
                      <CardFooter className="text-center d-block p-3">

                        <Button onClick={() => { this.publishDeployment() }} color="alternate" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                          <span className="mr-2 opacity-7">

                          </span>
                          <span className="mr-1">
                            Publish Configuration
                          </span>
                        </Button>

                      </CardFooter>)}
                  </Card>
                </Col>

              </Row>
            </TabPane>
            <TabPane tab='License Settings' key="3">
              <Row>

                <Col md='6'>
                  <Card className="main-card mb-6">
                    <CardBody>
                      <CardTitle>License</CardTitle>
                      <Form>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeLicenseSettings(e, 'managedConnections')} checked={this.state.licenseSettings['managedConnections']} />{' '}
                            Managed connections
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" onChange={(e) => this.handleChangeLicenseSettings(e, 'managedSettings')} checked={this.state.licenseSettings['managedSettings']} />{' '}
                            Managed settings
                          </Label>
                        </FormGroup>

                      </Form>

                    </CardBody>
                    {this.state.dirty && (
                      <CardFooter className="text-center d-block p-3">

                        <Button onClick={() => { this.publishDeployment() }} color="alternate" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                          <span className="mr-2 opacity-7">

                          </span>
                          <span className="mr-1">
                            Publish Configuration
                          </span>
                        </Button>

                      </CardFooter>)}

                  </Card>
                </Col>
              </Row>
            </TabPane>
          </Tabs>

        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
