import cookies from 'js-cookie'
import Api from './api'

export const setSessionData = jwt => {
  const key = Math.random().toString(36).slice(2)
  const middleIndex = (jwt.length % 2) ? ((jwt.length + 1) / 2) : (jwt.length / 2)
  const part1 = jwt.substr(0, middleIndex)
  const part2 = jwt.substr(middleIndex)

  cookies.set('token', JSON.stringify({ key, part1 }), { expires: 1, sameSite: 'strict', secure: true })
  localStorage.setItem('token', JSON.stringify({ key, part2 /*, username */ }))

  Api.token = jwt
}

export const loadSessionData = () => {
  const { key, part1 } = JSON.parse(cookies.get('token') || '{}')

  if (!key || !part1) {
    return
  }

  let stored

  try {
    stored = JSON.parse(localStorage.getItem('token')) || {}
  } catch (error) {
    console.log('Error parsing storage token:', error) // eslint-disable-line no-console
  }

  if (stored?.key === key) {
    Api.token = `${part1}${stored.part2}`
  }
}

export const clearSessionData = () => {
  cookies.remove('token')
  localStorage.removeItem('token')

  Api.token = null
}

export const hasSessionData = () => !!Api.token
