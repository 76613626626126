import React, { Component, Fragment } from 'react'

// Examples

import { CardFooter, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, Card, Col, Form, FormGroup, Table, Input, Button, CardHeader } from 'reactstrap'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faTrash} from '@fortawesome/free-solid-svg-icons'
import { faWindows, faApple, faLinux } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import Api from '../../utils/api'

class DeleteConfirmModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      name: '',
      id: '',
      deleteDisabled: true,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    if (field == 'name') {
      if (e.target.value == this.props.name) {
        this.setState({ deleteDisabled: false })
      } else {
        this.setState({ deleteDisabled: true })
      }
    }
    this.setState(newState)
  }

  handleDeleteDevice(/* id */) {
    var self = this

    Api.patch(`/devices/${this.props.id}`, { status: 'trashed' })
      .then(() => {
        self.props.loadDevices()
      })
  }

  render() {
    var self = this

    return (
      <span className="d-inline-block mb-2 mr-2">
               
        <a href="#" onClick={this.toggle}><FontAwesomeIcon className="ml-2 opacity-5" icon={faTrash}/></a>
        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>Disable Device</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                If you disable this device it will become unlicensed immediately.
                <br /><br />
                To disable this device, type &quot;<span style={{ color: 'red' }}>{self.props.name}</span>&quot;, then click the disable button.<br />

                <Input autoFocus value={this.state.name} onChange={(e) => self.handleChange(e, 'name')} type="text"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>Cancel</Button>
            <Button disabled={this.state.deleteDisabled} color="danger" className="btn-pill btn-widee fsize-1" size="md" onClick={this.handleDeleteDevice.bind(this)}>Disable</Button>{' '}
          </ModalFooter>
        </Modal>
      </span>
    )
  }
}

export class DevicesPage extends Component {
  state = {
    devices: [],
  }

  componentDidMount() {
    this.loadDevices()
  }

  loadDevices() {
    var self = this

    Api.get('/devices')
      .then(responseJSON => {
        self.setState({ devices: responseJSON })
      })
      .catch(() => {
        self.props.history.push('/users/sign_in')
      })
  }

  render() {
    return (
      <Fragment>
        <Container fluid>

          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader>
                                    Active Devices

                </CardHeader>
                <Table responsive hover striped borderless className="align-middle mb-0">
                  <thead>
                    <tr style={{ height: '1.5rem' }}>
                      <th style={{ width: '30px' }}></th>
                      <th>Name</th>
                      <th>User</th>
                      <th>Configuration</th>
                      <th>Platform</th>
                      <th>Last Seen</th>
                      <th></th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.state.devices.map(device => {
                      var platformIcon = <FontAwesomeIcon className="ml-2 opacity-5" icon={faWindows} />
                      if (device.platform.startsWith('darwin')) {
                        platformIcon = <FontAwesomeIcon className="ml-2 opacity-5" icon={faApple} />
                      }

                      if (device.platform.startsWith('linux')) {
                        platformIcon = <FontAwesomeIcon className="ml-2 opacity-5" icon={faLinux} />
                      }

                      if (device.platform.toLowerCase().includes('linux')) {
                        platformIcon = <FontAwesomeIcon className="ml-2 opacity-5" icon={faLinux} />
                      }

                      return (<tr key={device.id} style={{ height: '3rem', fontSize: '1rem' }} >
                        <td><FontAwesomeIcon className="ml-2 opacity-5" icon={faLaptop} /></td>
                        <td>{device.name}</td>

                        <td>{device.user ? (<Link to={`/users/${device.user.id}`}>{device.user.email}</Link>) : 'Unnamed user'}</td>
                        <td><Link to={`/configurations/${device.configuration.id}`}>{device.configuration.name}</Link></td>
                        <td>{platformIcon} {device.platform}</td>
                        <td>{moment(device.lastSeen).fromNow()}</td>
                        <td><DeleteConfirmModal name={device.name} id={device.id} loadDevices={this.loadDevices.bind(this)}/></td>
                      </tr>)
                    })}

                  </tbody>
                </Table>

                <CardFooter className="text-center d-block p-3">
                                    Total Devices: {this.state.devices.length}

                </CardFooter>

              </Card>
            </Col>
          </Row>

        </Container>
      </Fragment>
    )
  }
}

