import React, { Component, Fragment } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import { ConnectionTypes } from '../../../config/constants'
import CreatableSelect from 'react-select/creatable'

import {
  CardFooter,
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
} from 'reactstrap'

import Select from 'react-select'
import Api from '../../../utils/api'

export class DeploymentsEditDrive extends Component {
  state = { name: '', type: '', mountpoint: 'Z:', reconnectAtLogon: true, sites: [], region: '' }

  componentDidMount() {
    this.loadDeployment(this.props.match.params.id, this.props.match.params.driveId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id == this.props.match.params.id) {
      return
    }

    this.loadDeployment(this.props.match.params.id, this.props.match.params.driveId)
  }

  handleChange = (e, field, value = null) => {
    var newState = {}
    if (value != null) {
      newState[field] = value
    } else {
      newState[field] = e.target.value
    }
    this.setState(newState)
  }

  handleChangeChecked = (e, field) => {
    var newState = {}
    newState[field] = e.target.checked
    this.setState(newState)
  }

  loadDeployment(deploymentId, driveId) {
    if (driveId == 'new') {
      return
    }
    Api.get(`/configurations/${deploymentId}`)
      .then(responseJSON => {
        var favorites = JSON.parse(responseJSON['favorites'])
        favorites.map(entry => {
          if (entry.id == driveId) {
            this.setState(entry)
          }
        })
      })
  }

  handleSaveDrive() {
    var method = 'post'
    var theURL = '/configurations/' + this.props.match.params.id + '/drives'

    if (this.props.match.params.driveId != 'new') {
      method = 'patch'
      theURL += `/${this.props.match.params.driveId}`
    }

    Api[method](theURL, this.state)
      .then(() => {
        this.props.history.push('/configurations/' + this.props.match.params.id)
      })
  }

  handleDeleteDrive() {
    Api.delete(`/configurations/${this.props.match.params.id}/drives/${this.props.match.params.driveId}`)
      .then(() => {
        this.loadDeployment(this.props.match.params.id)
        this.props.history.push('/configurations/' + this.props.match.params.id)
      })
  }

  render() {
    var letterOptionsString = 'ABDEFGHIJKLMNOPQRSTUVWXYZ'

    var letterNodes = Array.prototype.map.call(letterOptionsString, letter => {
      return <option value={letter + ':'}>{letter + ':'}</option>
    })

    var defaultOption = null
    var selectNodes = Object.keys(ConnectionTypes).map(key => {
      var o = {
        value: key,
        label: (
          <div>
            <img
              style={{ height: '20px', paddingRight: '5px', marginTop: '-3px' }}
              src={ConnectionTypes[key].brandingIcon}
            />
            {ConnectionTypes[key].name}
          </div>
        ),
      }
      if (key == this.state.type) {
        defaultOption = o
      }
      return o
    })

    // var siteNodes = Array.prototype.map.call(this.state.sites, sites => {
    //   return <option value={sites['siteName']}>{sites['name']}</option>
    // })

    // var rootSite = ({ value: "", label: (<div>Root Site</div>) })
    // siteNodes.push(rootSite)
    var defaultOption2 = []

    var selectedSites = []
    if (this.state.region) {
      selectedSites = this.state.region.split(',')
    }

    var selectNodes2 = Array.prototype.map.call(this.state.sites, sites => {
      var o = { value: sites['siteName'], label: <div>{sites['name']}</div> }
      if (selectedSites.includes(sites['siteName'])) {
        defaultOption2.push(o)
        var ii = selectedSites.indexOf(sites['siteName'])
        if (ii !== -1) selectedSites.splice(ii, 1)
      }
      return o
    })

    for (var i = 0; i < selectedSites.length; i++) {
      var o = { value: selectedSites[i], label: <div>{selectedSites[i]}</div> }
      defaultOption2.push(o)
    }

    return (
      <Fragment>
        <CSSTransitionGroup
          component='div'
          transitionName='TabsAnimation'
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Container fluid>
            <Row>
              <Col md='9'>
                <Card className='main-card mb-6'>
                  <CardHeader>Drive Settings</CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label for='exampleSelect'>Connection Type</Label>
                        <Select
                          onChange={e => {
                            var d = this.state
                            d['type'] = e.value
                            d['name'] = ConnectionTypes[e.value].defaultNickname
                            if (ConnectionTypes[e.value].server) d['server'] = ConnectionTypes[e.value].server
                            if (ConnectionTypes[e.value].protocol) d['protocol'] = ConnectionTypes[e.value].protocol
                            if (ConnectionTypes[e.value].oauth) {
                              d['username'] = 'oauth'
                            }

                            this.setState(d)
                          }}
                          value={defaultOption}
                          type='select'
                          name='type'
                          options={selectNodes}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Nickname</Label>
                        <Input
                          type='text'
                          name='name'
                          onChange={e => {
                            this.handleChange(e, 'name')
                          }}
                          value={
                            this.state.type != '' && this.state.name == ''
                              ? ConnectionTypes[this.state.type].defaultNickname
                              : this.state.name
                          }
                        />
                      </FormGroup>
                      {this.state.type != '' && !ConnectionTypes[this.state.type].oauth && (
                        <Fragment>
                          <FormGroup>
                            <Label for='email'>Server</Label>
                            <Input
                              type='text'
                              name='username'
                              onChange={e => {
                                this.handleChange(e, 'server')
                              }}
                              value={this.state.server}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for='email'>Username</Label>
                            <Input
                              type='text'
                              name='username'
                              onChange={e => {
                                this.handleChange(e, 'username')
                              }}
                              value={this.state.username}
                            />
                          </FormGroup>
                        </Fragment>
                      )}
                      {this.state.type != '' && this.state.type == 's3iam' && (
                        <Fragment>
                          <FormGroup>
                            <Label for='iamRole'>S3 IAM Role</Label>
                            <Input
                              type='text'
                              name='iamRole'
                              onChange={e => {
                                this.handleChange(e, 'iamRole')
                              }}
                              value={this.state.server}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for='iamRole'>S3 IAM Role</Label>
                            <Input
                              type='text'
                              name='iamRole'
                              onChange={e => {
                                this.handleChange(e, 'iamRole')
                              }}
                              value={this.state.server}
                            />
                          </FormGroup>

                        </Fragment>
                      )}

                      {this.state.type == 'sharepoint' && (
                        <FormGroup>
                          <Label for='email'>Sites</Label>
                          <CreatableSelect
                            onChange={e => {
                              var sitesString = ''

                              if (e && e.length) {
                                var sites = e.map(site => {
                                  return site.value
                                })
                                sitesString = sites.join(',')
                              }

                              this.handleChange(e, 'region', sitesString)
                            }}
                            formatCreateLabel={inputValue => 'custom site: ' + inputValue}
                            isMulti
                            isClearable
                            value={defaultOption2}
                            type='select'
                            name='region'
                            options={selectNodes2}
                          />
                        </FormGroup>
                      )}

                      <FormGroup>
                        <Label for='exampleSelectMulti'>Windows Drive Letter</Label>
                        <Input
                          type='select'
                          name='mountpoint'
                          value={this.state.mountpoint}
                          onChange={e => {
                            this.handleChange(e, 'mountpoint')
                          }}
                        >
                          {letterNodes}
                        </Input>
                      </FormGroup>

                      <FormGroup>
                        <Label>Remote Path</Label>
                        <Input
                          type='text'
                          name='name'
                          onChange={e => {
                            this.handleChange(e, 'remotePath')
                          }}
                          value={this.state.remotePath}
                        />
                      </FormGroup>

                      <FormGroup check>
                        <Label check>
                          <Input
                            onChange={e => {
                              this.handleChangeChecked(e, 'reconnectAtLogon')
                            }}
                            type='checkbox'
                            checked={this.state['reconnectAtLogon']}
                          />{' '}
                          Connect Automatically
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            onChange={e => {
                              this.handleChangeChecked(e, 'deferAuthorization')
                            }}
                            type='checkbox'
                            checked={this.state['deferAuthorization']}
                          />{' '}
                          Defer Authentication
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            onChange={e => {
                              this.handleChangeChecked(e, 'attemptSSO')
                            }}
                            type='checkbox'
                            checked={this.state['attemptSSO']}
                          />{' '}
                          Attempt SSO
                        </Label>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter className='text-center d-block p-3'>
                    <Button
                      color='success'
                      onClick={e => {
                        e.preventDefault()
                        this.handleSaveDrive()
                      }}
                      className='btn-pill btn-shadow btn-wide fsize-1'
                      size='lg'
                    >
                      <span className='mr-2 opacity-7'></span>
                      <span className='mr-1'>Save Drive</span>
                    </Button>
                    {'   '}
                    <Button
                      className='btn-pill btn-shadow btn-wide fsize-1'
                      size='lg'
                      color='danger'
                      onClick={e => {
                        e.preventDefault()
                        this.handleDeleteDrive()
                      }}
                    >
                      <i className='pe-7s-trash btn-icon-wrapper'> </i>
                    </Button>
                  </CardFooter>
                </Card>
                <br /><br />
                <a href="https://docs.expandrive.com/teams/configurations#drive-settings">Help - Drive Settings</a>
              </Col>
            </Row>
          </Container>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}
