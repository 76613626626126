export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_DEVICES = 'UPDATE_DEVICES'
export const UPDATE_USERS = 'UPDATE_USERS'

export const UPDATE_CURRENT_ACCOUNT = 'UPDATE_CURRENT_ACCOUNT'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export function updateCurrentUser(user) {
  return { type: UPDATE_CURRENT_USER, user: user }
}

export function updateDevices(devices) {
  return { type: UPDATE_DEVICES, devices: devices }
}

export function updateUsers(users) {
  return { type: UPDATE_USERS, users: users }
}

export function updateCurrentAccount(account) {
  return { type: UPDATE_CURRENT_ACCOUNT, account: account }
}

export function toggleSidebar(open) {
  return { type: TOGGLE_SIDEBAR, open: open }
}
