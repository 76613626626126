import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
} from 'reactstrap'

import { toast, Bounce } from 'react-toastify'

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'

class Avatar extends React.Component {
  constructor(props) {
    super(props)

    var backgroundColor = (backgroundColor = { r: 255, g: 255, b: 255 })

    backgroundColor = this.HSVtoRGB(this.colorHash(this.props.currentUser.firstName), 1, 0.8)
    if (this.props.currentUser.firstName == '') {
      backgroundColor = { r: 255, g: 255, b: 255 }
    }

    this.state = {
      style: { backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})` },
    }
  }

  componentDidUpdate() {
    if (this.props.currentUser.firstName) {
      var backgroundColor = this.HSVtoRGB(this.colorHash(this.props.currentUser.firstName), 1, 1)

      this.setState({
        style: { backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})` },
      })
    }
  }

  initial() {
    var i = ''

    if (this.props.currentUser.firstName) i += this.props.currentUser.firstName.toUpperCase()[0]
    if (this.props.currentUser.lastName) i += this.props.currentUser.lastName.toUpperCase()[0]
    return i
  }

  colorHash(name) {
    var hash = 0,
      i,
      chr
    if (name == undefined || name.length === 0) return hash
    for (i = 0; i < name.length; i++) {
      chr = name.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return (hash + 2147483648.0) / 429496729.0
  }

  HSVtoRGB(h, s, v) {
    var r, g, b, i, f, p, q, t
    if (arguments.length === 1) {
      (s = h.s), (v = h.v), (h = h.h)
    }
    i = Math.floor(h * 6)
    f = h * 6 - i
    p = v * (1 - s)
    q = v * (1 - f * s)
    t = v * (1 - (1 - f) * s)

    switch (i % 6) {
      case 0:
        (r = v), (g = t), (b = p)
        break
      case 1:
        (r = q), (g = v), (b = p)
        break
      case 2:
        (r = p), (g = v), (b = t)
        break
      case 3:
        (r = p), (g = q), (b = v)
        break
      case 4:
        (r = t), (g = p), (b = v)
        break
      case 5:
        (r = v), (g = p), (b = q)
        break
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    }
  }

  render() {
    return (
      <a
        href='#'
        className={'avatar'}
        style={this.state.style}
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <span className={'avatarInitials'}>{this.initial()}</span>
      </a>
    )
  }
}

class UserBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  notify2 = () => (
    this.toastId = toast('You don\'t have any new items in your calendar for today! Go out and play!', {
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: 'bottom-center',
      type: 'success',
    })
  )

  render() {
    return (
      <Fragment>
        <div className='header-btn-lg pr-0'>
          <div className='widget-content p-0'>
            <div className='widget-content-wrapper'>
              <div className='widget-content-left'>
                <div className='widget-heading '>
                  {this.props.currentUser.firstName != null ? this.props.currentUser.firstName : ''}{' '}
                  {this.props.currentUser.lastName != null ? this.props.currentUser.lastName : ''}
                </div>
                <div className='widget-subheading'>ExpanDrive, Inc.</div>
              </div>
              <div className='widget-content-left '>
                <UncontrolledButtonDropdown>
                  <DropdownToggle color='link' className='p-0'>
                    <Fragment>
                      {this.props.currentUser.pictureURL && this.props.currentUser.pictureURL.length > 5 && (
                        <img width={42} className='rounded-circle' src={this.props.currentUser.pictureURL} alt='' />
                      )}
                      {this.props.currentUser.pictureURL == undefined ||
                        (this.props.currentUser.pictureURL.length == 0 && (
                          <Avatar currentUser={this.props.currentUser} />
                        ))}

                      <FontAwesomeIcon className='ml-2 opacity-8' icon={faAngleDown} />
                    </Fragment>
                  </DropdownToggle>
                  <DropdownMenu right className='rm-pointers dropdown-menu-xl'>
                    <div className='dropdown-menu-header'>
                      <div className='dropdown-menu-header-inner bg-info'>
                        <div
                          className='menu-header-image opacity-2'
                          style={{
                            backgroundImage: 'url(' + city3 + ')',
                          }}
                        />
                        <div className='menu-header-content text-left'>
                          <div className='widget-content p-0'>
                            <div className='widget-content-wrapper'>
                              <div className='widget-content-left mr-3'>
                                <img
                                  width={42}
                                  className='rounded-circle'
                                  src={this.props.currentUser.pictureURL}
                                  alt=''
                                />
                              </div>
                              <div className='widget-content-left'>
                                <div className='widget-heading'>{this.props.currentUser.name}</div>
                                <div className='widget-subheading opacity-8'>{this.props.currentUser.email}</div>
                              </div>
                              <div className='widget-content-right mr-2'>
                                <Button
                                  onClick={() => {
                                    window.location = '/users/sign_out'
                                  }}
                                  className='btn-pill btn-shadow btn-shine'
                                  color='focus'
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
})

export default connect(mapStateToProps)(UserBox)
